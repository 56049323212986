import React, { createContext, useState } from "react";
import ConfirmModal from "../templateux/confirm/confirm-modal";
export const ConfirmContext = createContext(null);

const ConfirmContextProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    prompt: "",
    isOpen: false,
    proceed: null,
    cancel: null
  });
  
  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
      <ConfirmModal />
    </ConfirmContext.Provider>
 );
};
export default ConfirmContextProvider;