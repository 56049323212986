import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { useConfirm } from "@/lib/utils/hooks/useConfirm";
import { Dialog, Transition } from "@headlessui/react";
import { hasData } from "@/lib/utils/hasData";

const ConfirmModal = () => {
  const { 
    prompt,
    isOpen = false,
    proceed,
    cancel 
  } = useConfirm();

  const inputRef = useRef(null)

  useEffect(()=>{
    if (inputRef.current) inputRef.current.focus()
  },[])
  useEffect(()=>{
    if (inputRef.current) inputRef.current.focus()
  },[isOpen])

  return (


                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog
                    as="div"
                    open={isOpen}
                    className="fixed inset-0  z-[1000] overflow-y-auto bg-[rgb(0,0,0,0.85)]"
                    onClose={()=> cancel()}
                    >
                    
                    <div className="min-h-screen px-4 text-center max-w-lg mx-auto">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Dialog.Overlay className="fixed inset-0 " />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-100"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        >
                        <div className="inline-block w-full border transform p-6 my-8 overflow-hidden text-left align-middle transition-all shadow-xl rounded-2xl
                                       bg-gray-900 border-gray-700">
                           
                            <div className="sm:flex items-center content-center">
                                <div className="flex-1">
                               
                                   
                                    {prompt.show_title == 1 &&
                                    <div className="flex items-center content-center border-b pb-4
                                                    border-gray-700
                                    ">
                                        <div className="flex-1">
                                             <Dialog.Title
                                                as="h3"
                                                className="text-2xl font-bold leading-6 
                                                text-white"
                                            >
                                                {prompt.title}
                                            </Dialog.Title>
                                        </div>
                                    </div>
                                    }
                                    {hasData(prompt.body) &&
                                    <div className="mt-2 text-center max-h-[300px] overflow-y-auto"
                                    
                                    >
                                        {prompt.body}
                                    </div>
                                    }



                                </div>
                                
                            </div>

                            <div className="flex flex-row-reverse items-center contents-center justify-start mt-4">
                               
                                {prompt.show_confirm == 1 &&
                                <button
                                    
                                    tabIndex={0}
                                    color="primary"
                                    autoFocus={true}
                                    ref={inputRef}
                                    onClick={proceed}
                                    className="px-4 py-2 ml-4 bg-blue-500 text-white cursor-pointer font-bold rounded-md hover:opacity-80"
                                >
                                    {prompt.confirm}
                                </button>
                                }
                                {prompt.show_cancel == 1 &&
                                <button
                                    tabIndex={0}
                                    color="secondary"
                                    onClick={cancel}
                                    className="border  border-gray-700 px-4 py-2  cursor-pointer rounded-md hover:opacity-80  hover:border-gray-200"
                                >
                                    {prompt.cancel}
                                </button>
                                }
                            </div>

                            
                            
                        </div>
                        </Transition.Child>
                    </div>
                    </Dialog>
                </Transition>

  );
};
export default ConfirmModal;