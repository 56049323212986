import React, { useEffect } from 'react'
import Context from '@/components/auth/context'
import { getSession, useSession } from 'next-auth/client'
import Cookies from 'js-cookie'
import axios from 'axios'
import {ContextValue} from './types'
import { useUserAuth } from '@/lib/utils/hooks/useUserAuth'
import { hasData } from '@/lib/utils/hasData'

type Props = {
  children: React.ReactNode;
}

function Auth({ children }: Props) {
  const [contextValue, set_contextValue] = React.useState<ContextValue>({ isLoading: true })
  const [session, isLoading] = useSession()


  /** 
   * This route requires authentication and the user is not authenticated. We are already 
   * rendering the unauthorized component below, but lets update the URL without reloading the page
   */
  // useEffect(() => {
  //   if (isRouteRequiresAuth(router.pathname) && contextValue === null) {
  //     router.replace(window.location.pathname, '/unauthorized', { shallow: true });
  //   }
  // }, [contextValue]);
    
  useEffect(() => {
    const { usertoken } = Cookies.get();
    const { user, sessionToken } = Cookies.get();

    if (!isLoading) {
      
      if (session) {

        if (!hasData(contextValue?.id)) {

          //data doesnt exist so pull from db as last resort
          axios('/api/private/user/select-prefs').then(({data}) => {
            if (data) {

              set_contextValue({ ...data
                                , isLoading: false
                                , set_contextValue })
            } else {
              set_contextValue({ id: 0 //Math.floor(100000 + Math.random() * 900000)*-1
                                , user_name:'Lurker'
                                , isLoading: false }) // not authenticated
            }
          }).catch((err) => {
            console.log('<Auth /> could not fetch user');
          })

        }
        
      } else {
        set_contextValue({  id: 0 //Math.floor(100000 + Math.random() * 900000)*-1
                          , user_name:'Lurker'
                          , isLoading: false }) // not authenticated
      }
    }

  }, [session, isLoading, contextValue?.id])

  // This route requires authentication and we aren't sure yet if the user is authenticated
  // if (isRouteRequiresAuth(router.pathname) && contextValue === undefined) {
  //   return <Loading />
  // }

  // This route requires authentication and the user is not authenticated
  // if (isRouteRequiresAuth(router.pathname) && contextValue === null) {
  //   return <Unauthorized />
  // }

  return (
    <Context.Provider value={contextValue} >
      {children}
    </Context.Provider>
  )
}

export default Auth
